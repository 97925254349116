<template>
  <header class="bg-white top-0">
    <v-container class="pa-5 text-center text-md-left">
      <img
        :src="logo"
        width="115px"
        height="55px"
        :alt="brand"
      />
    </v-container>
  </header>
</template>

<script lang="ts" setup>
const brand = import.meta.env.VITE_BRAND_THEME;
const logo = `/${import.meta.env.VITE_BRAND_THEME}/logo.svg`;
</script>

<style>
header {
  box-shadow: 0px 8px 28px 0px #0000000d;
  margin-bottom: 80px;
}
</style>
