import DOMPurify from "isomorphic-dompurify";

const formatHtml = (element: HTMLElement, value: string) => {
  element.innerHTML = DOMPurify.sanitize(value);
};

export const vI18nHtml = {
  mounted: (el: HTMLElement, binding: { value: string }) =>
    formatHtml(el, binding.value),
  updated: (el: HTMLElement, binding: { value: string }) =>
    formatHtml(el, binding.value),
};
