import { onMounted, onUnmounted } from 'vue';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const WHITELISTED_QUERY_PARAMS = ['redirectURL'];
const anonymizeUrl = (url: string) => {
  // redact all query parameters from the url that are not redirect urls
  const [baseUrl, queryString] = url.split('?');
  if (!queryString) {
    return url;
  }

  const params = new URLSearchParams(queryString);
  for (const key of params.keys()) {
    if (!WHITELISTED_QUERY_PARAMS.includes(key)) {
      params.set(key, '***');
    }
  }

  return `${baseUrl}?${params.toString()}`;
};
const createViewName = (url: string) => {
  const { pathname } = new URL(url);

  return pathname;
};

export const useDatadog = () => {
  onMounted(() => {
    datadogLogs.init({
      clientToken: import.meta.env.VITE_DATADOG_LOG_TOKEN,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: import.meta.env.VITE_DATADOG_SERVICE,
      version: import.meta.env.VITE_DATADOG_VERSION,
      env: import.meta.env.VITE_ENV,
      forwardErrorsToLogs: true,
      sessionSampleRate: Number(import.meta.env.VITE_DATADOG_LOG_SAMPLE_RATE),
      beforeSend: (event) => {
        event.view.url = anonymizeUrl(event.view.url);

        if (event.path) {
          event.path = anonymizeUrl(event.path as string);
        }

        return true;
      },
    });

    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: import.meta.env.VITE_DATADOG_SITE,
      service: import.meta.env.VITE_DATADOG_SERVICE,
      env: import.meta.env.VITE_ENV,
      version: import.meta.env.VITE_DATADOG_VERSION,
      sessionSampleRate: Number(import.meta.env.VITE_DATADOG_SAMPLE_RATE),
      sessionReplaySampleRate: Number(import.meta.env.VITE_DATADOG_REPLAY_RATE),
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: (event) => {
        event.view.url = anonymizeUrl(event.view.url);
        event.view.name = createViewName(event.view.url);

        if (event.resource?.url) {
          event.resource.url = anonymizeUrl(event.resource.url);
        }
        if (event.error?.resource?.url) {
          event.error.resource.url = anonymizeUrl(event.error.resource.url);
        }

        return true;
      },
    });

    const browserLanguage = navigator.language;
    datadogRum.setGlobalContextProperty('@browser.lang', browserLanguage);
  });

  onUnmounted(() => {
    datadogRum.stopSessionReplayRecording();
  });
};
