import { createI18n } from 'vue-i18n';

export const localeByCountry: Record<string, Function> = {
  be: () => (navigator.language.includes('nl') ? 'nl-BE' : 'fr-BE'),
  de: () => 'de-DE',
  es: () => 'es-ES',
  fr: () => 'fr-FR',
  it: () => 'it-IT',
};

const mainFallbackLanguage = 'fr-FR';

// Translations
let locale: string;
try {
  locale =
    localeByCountry[import.meta.env.VITE_COUNTRY]() || mainFallbackLanguage;
} catch (e) {
  locale = mainFallbackLanguage;
}

const loadMessages = async (brand: string) => {
  return import(`@/i18n/${brand}/${locale}.json`)
    .catch(() => import(`@/i18n/${brand}/${mainFallbackLanguage}.json`))
    .then((translation) => translation.default);
};

const messages = await loadMessages(import.meta.env.VITE_BRAND_THEME);

// AWS amplify
import { I18n } from 'aws-amplify';
I18n.setLanguage(locale);
I18n.putVocabularies({ [locale]: messages });

const i18n = createI18n({
  legacy: false, // Use Composition API
  locale: locale,
  fallbackLocale: mainFallbackLanguage, // Fallback locale if no translation found
  messages: { [locale]: messages },
});

export default i18n;
