// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n'; // Import the i18n configuration
import pageSetup from './plugins/setup-page';
import { vI18nHtml } from './directives/i18n-html';
import { datadogLogs } from '@datadog/browser-logs'

// Amplify
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { getVuetify } from './plugins/vuetify';

Amplify.configure(awsExports);

// Vuetify
const vuetify = await getVuetify();

// Creating App
const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(pageSetup);
app.directive('i18nHtml', vI18nHtml);

app.config.errorHandler = (err, vm, info) => {
  console.error('error', { err, vm, info });
  datadogLogs.logger.error('error', { err, vm, info })
};

app.mount('#app');
