import { reactive, readonly } from "vue";
import { Store } from "@/types";

const data = reactive<Store>({
  email: undefined,
  ownerCode: undefined,
  customerId: undefined,
  redirectURL: undefined,
  idToken: undefined
});

export function useStore() {
  const setData = (newData: Partial<Store>) => {
    Object.assign(data, newData)
  }

  return {
    data: readonly(data),
    setData
  };
}
